<template>
  <base-form-item
      :field="field"
      :schemaVersion="schemaVersion"
  >
    <static-table
        :data="JSON.parse(value).results"
        :params="params"
        :thead="thead"
        v-if="value"
    />
    <div
        class="el-form-text"
        v-else
    >
      <span class="no-date-title">{{ $t('system.no_data') }}</span>
    </div>
  </base-form-item>
</template>

<script>
import StaticTable from '../../../../ABtable/StaticTable.vue';
import BaseFormItem from '../../UI/FormItem.vue';
import abstractForm from '../../mixin/index';

export default {
  mixins: [abstractForm],
  name: 'fieldTestsResults',
  components: {
    StaticTable,
    BaseFormItem,
  },
  data() {
    return {
      thead: [
        {
          label: 'system.diseaseName',
          key: 'diseaseName',
          translate: true,
          translateKey: 'result',
          sort: false,
        },
        {
          label: 'system.result',
          key: 'result',
          translate: true,
          translateKey: 'result',
          sort: false,
        },
      ],
      params: {
        column: 'id',
        direction: 'desc',
        per_page: 10,
        page: 1,
      },
      total: null,
    };
  },
  beforeMount() {
    if (this.value) {
      this.total = JSON.parse(this.value).results.length;
    }
  },
};
</script>

<style scoped>

</style>
